<template>
  <div class="card shadow-sm">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        เลือกงวดบัญชี
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary me-2"
          style="font-size: 14px; font-weight: bold"
          @click="goToList()"
        >
          ตกลง
        </button>

        <button
          type="button"
          class="btn btn-sm btn-light me-2"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew()"
        >
          ยกเลิก
        </button>
      </div>
    </div>
    <div class="card-body">
      <div class="row d-flex justify-content-center">
        <label for="validationTooltip01" class="required form-label col-sm-8"
          >งวดที่</label
        >
        <div class="col-sm-8">
          <select class="form-select" aria-label="Select example">
            <option>เลือกงวด</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </select>
          <div class="invalid-feedback text-end">กรุณาเลือก</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({}),
  methods: {
    goToList() {
      this.$router.push("/manage-system/op2/list");
    },
  },
};
</script>
